export const tableColumn = [
	{
		label: '姓名',
		prop: 'username',
		width: '120px',
		fixed: true,
	},
	{
		label: '日期',
		prop: 'briefingDate',
		width: '200px',
	},

	{
		label: '通次',
		prop: 'talkNum',
		width: '120px',
	},
	{
		label: '通时',
		prop: 'talkTimeMinute',
		width: '120px',
	},
	{
		label: '今日签约',
		prop: 'signNum',
		width: '120px',
	},
	{
		label: '微信好友数量',
		prop: 'wxFriendsNum',
		width: '150px',
	},
	{
		label: '单日新增微信好友数量',
		prop: 'wxNewFriendsNum',
		width: '200px',
	},
	{
		label: '今日转介绍客户',
		prop: 'toIntroduceNum',
		width: '150px',
	},
	{
		label: '明日上门量',
		prop: 'tomorrowToDoor',
		width: '150px',
	},
	{
		label: '补充',
		prop: 'remark',
		width: '300px',
	},
	{
		label: '操作',
		prop: 'id',
		width: '100px',
		isCustomize: true,
		tableType: 'workReport',
		fixed: 'right',
	},
]

export const forms = [
	[
		{
			label: '今日转介绍客户',
			type: 'inputNumber',
			attr: 'toIntroduceNum',
			column: 12,
			labelWidth: '200px',
		},
		{
			label: '今日签约客户',
			type: 'inputNumber',
			attr: 'signNum',
			column: 12,
			labelWidth: '200px',
		},
	],
	[
		{
			label: '微信好友数量',
			type: 'inputNumber',
			attr: 'wxFriendsNum',
			column: 12,
			labelWidth: '200px',
		},
		{
			label: '当日新增微信好友数量',
			type: 'inputNumber',
			attr: 'wxNewFriendsNum',
			column: 12,
			labelWidth: '200px',
		},
	],
	[
		{
			label: '明日上门量',
			type: 'inputNumber',
			attr: 'tomorrowToDoor',
			column: 12,
			labelWidth: '200px',
		},
		{
			label: '备注',
			type: 'input',
			inputType: 'textarea',
			attr: 'remark',
			placeholder: '请输入备注',
			column: 12,
			labelWidth: '200px',
		},
	],
]
