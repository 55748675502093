<template>
  <div>
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @changeDept="changeDept"
      @submit="handleFilterSubmit"
      @reset="handleReset"
    />
    <div class="system-btn user-btn">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="handleNew"
      >添加工作简报</el-button>
<!--      <el-button
        size="small"
        @click="downloadExcel"
      >导出数据</el-button>-->
    </div>
    <div class="container">
      <div class="right">
        <RoleTable
          v-if="tableData"
          :tableColumn="tableColumn"
          :tableData="tableData"
          @edit="goToEdit"
        />
        <RolePagination
          :total="total"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <RoleDialog
      :title="title"
      :isShowDialog="isShowDialog"
      :forms="forms"
      :formData="formData"
      :width="800"
      @submit="handleSubmit"
      @close="handleColse"
    />

  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { ElButton, ElInput, ElMessage, ElTree } from "element-plus";
import { tableColumn, forms } from "./index.js";
import RoleTable from "@/components/table";
import RolePagination from "@/components/pagination";
import RoleDialog from "@/components/dialog-form";
import { useStore } from "vuex";
import {
  postWorkReport,
  putWorkReport,
  getWorkReport,
} from "@/api/method/report";
import { getUserByDept } from "@/api/method/common";
import { reset } from "@/until";

export default {
  name: "SystemRole",
  components: {
    FilterForm,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    [ElTree.name]: ElTree,
    RoleTable,
    RolePagination,
    RoleDialog,
  },
  setup() {
    const { dispatch, state } = useStore();

    const formData = reactive({
      id: null,
      toIntroduceNum: null,
      signNum: null,
      wxFriendsNum: null,
      wxNewFriendsNum: null,
      tomorrowToDoor: null,
      remark: null,
    });
    const filterData = reactive({
      deptId: null,
      userId: null,
      date: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择顾问",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "100px",
        },
        {
          label: "上门时间",
          type: "dateRange",
          attr: "date",
          column: 8,
          labelWidth: "80px",
          valueFormat: "YYYY-MM-DD",
        },
        {
          type: "button",
          column: 4,
        },
      ],
    ]);

    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const company = ref("");
    const tableData = computed(() => state.workList && state.workList.records);
    const total = computed(() => state.workList && state.workList.total);
    const treeData = computed(() => state.menuTree);

    const isShowDialog = ref(false);
    const title = ref("添加");

    const handleNew = () => {
      isShowDialog.value = true;
    };
    const handleColse = () => {
      isShowDialog.value = false;
      reset(formData);
      formData.type = 1;
    };

    const getList = () => {
      const { date, ...param } = filterData;

      const startTime = date && date[0];
      const endTime = date && date[1];
      dispatch("workList", { ...pagination, ...param, startTime, endTime });
    };

    onMounted(() => {
      getList();
      getDepartList();
      //   getDepartTree();
      //   getRoleList();
    });
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };
    const idArr = ref([]);
    const handleSelectionChange = (arr) => {
      idArr.value = arr.map((item) => item.id);
    };

    const handleNodeClick = () => {};

    const handleSubmit = async () => {
      const { id, ...params } = formData;
      if (id) {
        const res = await putWorkReport({ id, ...params });
        if (res.code == 200) {
          ElMessage.success("编辑成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      } else {
        const res = await postWorkReport(params);
        if (res.code == 200) {
          ElMessage.success("保存成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      }
    };
    const getWorkDetail = async (id) => {
      const res = await getWorkReport(id);
      const {
        toIntroduceNum,
        wxFriendsNum,
        wxNewFriendsNum,
        tomorrowToDoor,
        remark,
        signNum,
      } = res.data;
      formData.id = id;
      formData.toIntroduceNum = toIntroduceNum;
      formData.wxFriendsNum = wxFriendsNum;
      formData.wxNewFriendsNum = wxNewFriendsNum;
      formData.tomorrowToDoor = tomorrowToDoor;
      formData.remark = remark;
      formData.signNum = signNum;
    };
    const goToEdit = async (id) => {
      isShowDialog.value = true;
      title.value = "编辑";

      getWorkDetail(id);
    };

    const handleFilterSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const downloadExcel = () => {};

    return {
      filters,
      filterData,
      company,
      tableColumn,
      tableData,
      handleNew,
      isShowDialog,
      forms,
      formData,
      total,
      handleSelectionChange,
      treeData,
      handleNodeClick,
      handleSubmit,
      handleColse,
      title,
      goToEdit,
      changeDept,
      handleFilterSubmit,
      handleReset,
      downloadExcel,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  display: flex;
  height: auto;
  .left {
    width: 250px;
    background: #f5f6f9;
    margin-right: 24px;
    padding: 20px;
  }
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block {
  margin-bottom: 20px;
}

.company-block .el-input__inner {
  background: white !important;
}
.depart-tree {
  background: #f5f6f9;
  .el-tree-node__content {
    background: #f5f6f9;
  }
}
</style>